export const fixtureTab = [
  {
    id: 1,
    title: "all"
  },
  {
    id: 2,
    title: "male"
  },
  {
    id: 3,
    title: "female"
  },
  {
    id: 4,
    title: "t20"
  },
  {
    id: 5,
    title: "oneday"
  },
  {
    id: 6,
    title: "test"
  }
]

export const fixtureSeriesData = [
  {
    id: 1,
    title: "all"
  },
  {
    id: 2,
    title: "t20"

  },
  {
    id: 3,
    title: "oneday"

  },
  {
    id: 4,
    title: "test"

  }
]