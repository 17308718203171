import React from 'react'
import '../matchFantasy/matchFantasy.css'
const MatchHistory = () => {
  return (
    <div className='match-live-contaisner'>
  <h2 className='coming-soon'>Coming Soon</h2>
    </div>
  )
}

export default MatchHistory